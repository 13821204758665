
import { Options, Vue } from "vue-class-component";

import Spotify from "../components/Spotify.vue";
import SearchResultsComponent from "../components/SearchResultsComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import QueueComponent from "@/components/QueueComponent.vue";
import LoginModalComponent from "@/components/LoginModalComponent.vue";

@Options({
  name: "HomePage",
  components: {
    Spotify,
    SearchResultsComponent,
    NavbarComponent,
    QueueComponent,
    LoginModalComponent,
  },
})
export default class App extends Vue {}
