
import { SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import SongSearchComponent from "./ElementComponents/SongSearchComponent.vue";

@Options({
  components: {
    SongSearchComponent
  },
  name: "SearchResults"
})
export default class SearchResultsComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
