import {
  createRouter,
  RouteRecordRaw,
  createWebHistory,
  createWebHashHistory
} from "vue-router";
import Home from "../views/Home.vue";
import Auth from "../views/AuthView.vue";
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  }
  // {
  //   path: "/:pathMatch(.*)",
  //   name: "Catch all",
  //   component: NotFound,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
