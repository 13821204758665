<template>
  <div class="row bg-primary">
    <div class="col-3"><i class="bi bi-person-fill"></i></div>
    <div class="col-9 overflow-hidden">
      <strong>{{ user.name }}</strong>
    </div>
  </div>
  <div class="row bg-primary">
    <button class="btn btn-primary text-dark" @click="copyInviteLink()">
      Copy invite code
      <i class="bi bi-clipboard"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { AppUser, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "User",
})
export default class User extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly user!: AppUser;

  copyInviteLink() {
    const shareUrl = `${window.location.protocol}//${window.location.host}/?lobby_id=${this.syncClient.internalStore.me.p2pToken}`;
    navigator.clipboard.writeText(shareUrl);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
