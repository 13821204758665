<template>
  <input
    v-model="searchString"
    type="search"
    placeholder="Search for songs..."
    class="form-control"
    @input="onSearchChanged()"
  />
  <div class="pt-3 d-grid text-nowrap btn-group-vertical">
    <PlaylistElement
      v-for="playlist in syncClient.internalStore.playlists"
      :key="playlist.id"
      :playlist="playlist"
    />
  </div>
</template>
<script lang="ts">
import { InternalStore, internalStoreSymbol, syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import PlaylistElement from "@/components/ElementComponents/PlaylistElement.vue";
import { Song, SyncClient } from "@/lib/lib";

@Options({ name: "Navbar", components: { PlaylistElement } })
export default class NavbarComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  searchString = "";
  onSearchChanged() {
    // Search and display results
    console.log(this.searchString);
    // Dont search
    if (this.searchString) {
      this.syncClient.internalStore.api
        .search(this.searchString, ["track"])
        .then((results) => {
          this.syncClient.internalStore.searchResults = [];
          if (results.tracks) {
            results.tracks.items.forEach((track) => {
              const toAdd: Song = {
                name: track.name,
                artist: track.artists[0].name,
                spotifyId: track.id,
                duration: track.duration_ms,
              };
              this.syncClient.internalStore.searchResults.push(toAdd);
            });
          }
        });
    }
  }
}
</script>
