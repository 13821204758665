<template>
  <button class="btn btn-primary overflow-hidden">{{ playlist.name }}</button>
</template>

<script lang="ts">
import { Playlist, Song } from "@/lib/lib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "PlaylistElement",
})
export default class PlaylistElement extends Vue {
  @Prop() readonly playlist!: Playlist;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
