<template>
  <li
    class="
      list-group-item
      bg-primary
      d-flex
      justify-content-between
      align-items-start
    "
  >
    <div class="ms-2 me-auto">
      <div class="fw-bold">{{ song.name }}</div>
      {{ song.artist }}
    </div>
    <span>
      <button class="btn btn-success" @click="play()">
        <i class="bi bi-play"></i>
      </button>
    </span>
    <span>
      <button class="btn btn-danger" @click="onRemove()">
        <i class="bi bi-dash-circle"></i></button
    ></span>
  </li>
</template>

<script lang="ts">
import { Song, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "Queue Element",
})
export default class SongQueueComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly song!: Song;
  onRemove() {
    this.syncClient.removeFromQueue(this.song);
    
  }
  play() {
    this.syncClient.playSong(this.song);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
