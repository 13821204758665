<template>
  <div v-if="syncClient.internalStore.loggedIn">
    <ul class="list-group">
      <li class="list-group-item bg-primary mb-3">
        <User :user="syncClient.internalStore.me" />
      </li>
      <li
        class="list-group-item bg-primary"
        v-for="user in syncClient.internalStore.peers"
        :key="user.name"
      >
        <User :user="user" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { syncClientSymbol } from "../store/index";
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import User from "@/components/User.vue";
import { SyncClient } from "@/lib/lib";

@Options({
  components: {
    User,
  },
  name: "Socials",
})
export default class Spotify extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
