<template>
  <ul class="list-group">
    <SongQueueComponent
      v-for="song in syncClient.sharedStore.songQueue"
      :key="song.id"
      :song="song"
    />
  </ul>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { syncClientSymbol } from "@/store";
import SongQueueComponent from "@/components/ElementComponents/SongQueueComponent.vue";
import { SyncClient } from "@/lib/lib";
@Options({
  components: {
    SongQueueComponent,
  },
  name: "Queue",
})
export default class QueueComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
</script>
<style scoped>
</style>
