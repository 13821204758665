
import { AppUser, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "User",
})
export default class User extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly user!: AppUser;

  copyInviteLink() {
    const shareUrl = `${window.location.protocol}//${window.location.host}/?lobby_id=${this.syncClient.internalStore.me.p2pToken}`;
    navigator.clipboard.writeText(shareUrl);
  }
}
