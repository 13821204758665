import Peer from "peerjs";
import Automerge, { Doc, from, SyncState } from "automerge";
import {
  createInternalStore,
  createSharedStore,
  InternalStore,
  SharedStore
} from "@/store";

export interface AppUser {
  name: string | undefined;
  p2pToken: string;
}

export interface Song {
  spotifyId: string;
  name: string;
  artist: string;
  duration: number;
}

export interface Playlist {
  name: string;
  id: string;
}

export class SyncClient {
  readonly peer: Peer;
  private _doc: Doc<SharedStore> = from(createSharedStore());
  private syncStates: Map<string, SyncState> = new Map();
  private connections: Map<string, Peer.DataConnection> = new Map();
  private logging: boolean;
  private sharedStore: SharedStore = createSharedStore();
  // To clear the last Call to the next Song
  private nextSongCall = 0;

  internalStore: InternalStore = createInternalStore();

  constructor(logging = false) {
    this.logging = logging;
    this.peer = new Peer(this.internalStore.me.p2pToken, {
      debug: 2,
      // host: "peerjs-server.herokuapp.com",
      host: "peerjs.92k.de",
      secure: true,
      port: 443
    });
    this.peer.on("open", id => {
      this.log(`Connected as ${id}`);
    });
    // incoming connection
    this.peer.on("connection", conn => {
      this.connect(conn.peer, conn);
    });
  }

  connect(peerId: string, incomingConnection?: Peer.DataConnection) {
    // only connect if not already connected to peer
    if (this.connections.get(peerId)) {
      this.log(`Already connected to ${peerId}.`);
      return;
    }

    // incommingConnection is only set if the connection is incomming from another peer
    // if this client tries to connect to another client this.peer.connect() is used instead to create a connnection
    const p2pConnection = incomingConnection || this.peer.connect(peerId);

    this.syncStates.set(peerId, Automerge.initSyncState());
    this.connections.set(peerId, p2pConnection);

    const cleanup = () => {
      this.syncStates.delete(peerId);
      this.connections.delete(peerId);
      this.log("Connection closed");
    };
    p2pConnection.on("open", () => {
      this.log("Connection established");
      p2pConnection.on("close", cleanup);
      p2pConnection.on("error", cleanup);
      // importante
      p2pConnection.serialization = "none";
      // The actual SyncConnection is only opened after the p2pConnection is ready to send data.
      // Otherwise the SyncConnection will attempt to send changes into an unopened connection.

      p2pConnection.on("data", changes => {
        changes = new Uint8Array(changes);
        const syncState = this.syncStates.get(peerId);
        const [updatedDoc, nextSyncState, patch] = Automerge.receiveSyncMessage(
          this.doc,
          syncState as SyncState,
          changes
        );
        if (patch) (window as any).patch = patch;
        this.doc = updatedDoc;
        this.syncStates.set(peerId, nextSyncState);
        this.updatePeers();
      });
      this.updatePeers();
      this.log(`Added ${peerId} to connections.`);
    });
  }

  private mutate(change_function: (doc: SharedStore) => void) {
    const updatedDoc = Automerge.change(this.doc, change_function);
    this.doc = updatedDoc;
    // notify peers of changes.
    this.updatePeers();
  }

  private updatePeers() {
    this.connections.forEach(
      (connection: Peer.DataConnection, peer: string) => {
        const syncState = this.syncStates.get(peer);
        const [nextSyncState, syncMessage] = Automerge.generateSyncMessage(
          this.doc,
          syncState as SyncState
        );
        this.syncStates.set(peer, nextSyncState);
        // syncMessage is null if Peers are fully synced
        if (syncMessage) {
          connection.send(syncMessage);
        }
      }
    );
  }

  addToQueue(song: Song) {
    this.mutate(doc => {
      doc.songQueue.add(song);
    });
    this.log(`Added ${song.name} to queue.`);
  }

  removeFromQueue(song: Song) {
    this.mutate(doc => {
      const toRemove = doc.songQueue.rows.find(obj1 => {
        return obj1.spotifyId === song.spotifyId;
      });
      if (toRemove) doc.songQueue.remove(toRemove.id);
    });
    this.log(`Removed ${song.name} to search results.`);
  }

  playSong(song: Song) {
    this.removeFromQueue(song);
    this.mutate(doc => {
      doc.currentlyPlaying = {
        name: song.name,
        artist: song.artist,
        duration: song.duration,
        spotifyId: song.spotifyId
      };
    });
    this.internalStore.api
      .play({
        uris: [`spotify:track:${song.spotifyId}`]
      })
      .then(_ => {
        // Only Play next song if no error
        this.nextSongCall = setTimeout(_ => {
          this.nextSong();
        }, song.duration);
      });

    this.log(`Playing: ${song.name}.`);
  }

  nextSong() {
    const toPlay = this.sharedStore.songQueue.rows[0];
    // if there is nothing in the q
    if (toPlay) this.playSong(toPlay);
  }

  private log(data: string) {
    if (this.logging) {
      console.log(data);
    }
  }

  // overwrite setter to listen to changes
  set doc(doc: Doc<SharedStore>) {
    this._doc = doc;
    this.log(`Changed doc to ${JSON.stringify(doc)}.`);

    // Update Store
    if (
      this.sharedStore.currentlyPlaying.spotifyId !==
      doc.currentlyPlaying.spotifyId
    ) {
      this.log(
        `Changed song from ${this.sharedStore.currentlyPlaying.name} to ${doc.currentlyPlaying.name}`
      );
      this.sharedStore.currentlyPlaying = doc.currentlyPlaying;
      this.playSong(doc.currentlyPlaying);
    }
    this.sharedStore.songQueue = doc.songQueue;
  }

  get doc() {
    return this._doc;
  }
}
