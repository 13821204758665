
import { Vue } from "vue-class-component";

export default class Auth extends Vue {
  mounted() {
    // spotify authentication flow
    // token is set in the redirect uri
    // token is sent via post message that is handled in Home.vue
    const params = new URLSearchParams(window.location.hash);
    window.opener.postMessage({ token: params.get("#access_token") });
    window.close();
  }
}
