
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { syncClientSymbol } from "@/store";
import SongQueueComponent from "@/components/ElementComponents/SongQueueComponent.vue";
import { SyncClient } from "@/lib/lib";
@Options({
  components: {
    SongQueueComponent,
  },
  name: "Queue",
})
export default class QueueComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
