
import { Options, Vue } from "vue-class-component";
import Spotify from "./components/Spotify.vue";

@Options({
  components: {
    Spotify,
  },
  name: "App",
})
export default class App extends Vue {}
