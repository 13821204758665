
import { Song, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "Song",
})
export default class SongComponentSearch extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly song!: Song;
  onAdd() {
    console.log(`Added ${this.song.name} to search results`);
    this.syncClient.addToQueue(this.song);
  }
}
