
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { syncClientSymbol } from "@/store";
import cuid from "cuid";
import { SyncClient } from "@/lib/lib";

@Options({ name: "LoginModal" })
export default class LoginModalComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;

  showModal = true;

  mounted() {
    window.addEventListener("message", this.onMessageUpdate);
    this.showModal = true;
  }

  login(token: string) {
    this.syncClient.internalStore.api.setAccessToken(token);
    this.syncClient.internalStore.api
      .getMe()
      .then((user) => {
        this.syncClient.internalStore.me.name = user.display_name;
        this.syncClient.internalStore.loggedIn = true;
        this.afterLogin();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  afterLogin() {
    // fetch playlists
    this.syncClient.internalStore.api.getUserPlaylists().then((playlists) => {
      playlists.items.forEach((playlist) => {
        this.syncClient.internalStore.playlists.push({
          name: playlist.name,
          id: playlist.id,
        });
      });
    });
  }

  onMessageUpdate(event: MessageEvent) {
    if (typeof event.data === "object" && "token" in event.data) {
      this.login(event.data.token);
    }
  }

  authenticate() {
    const scopes = "user-read-private user-read-email user-modify-playback-state";
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no, width=500,height=820,left=-1000,top=-1000`;
    const authUrl = `https://accounts.spotify.com/authorize?client_id=e6452d86f99b4f2ca9ee0ed983a20cc6&redirect_uri=${
      window.location.protocol
    }//${window.location.host}/auth&scope=${encodeURIComponent(
      scopes
    )}&response_type=token&internalStore=123`;
    window.open(authUrl, "test", params);
  }

  joinOrCreate() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("lobby_id")) {
      return "Join";
    }
    return "Create";
  }

  startSession() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("lobby_id");
    if (token) {
      this.syncClient.connect(token);
    }
    this.showModal = false;
  }
}
