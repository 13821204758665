
import { Playlist, Song } from "@/lib/lib";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "PlaylistElement",
})
export default class PlaylistElement extends Vue {
  @Prop() readonly playlist!: Playlist;
}
