<template>
  <ul class="list-group">
    <!-- Song Components -->
    <SongSearchComponent
      v-for="song in syncClient.internalStore.searchResults"
      :key="song.id"
      :song="song"
    />
  </ul>
</template>

<script lang="ts">
import { SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import SongSearchComponent from "./ElementComponents/SongSearchComponent.vue";

@Options({
  components: {
    SongSearchComponent
  },
  name: "SearchResults"
})
export default class SearchResultsComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
