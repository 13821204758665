import { createApp } from "vue";
import App from "./App.vue";
import {
  internalStoreSymbol,
  createInternalStore,
  createSharedStore,
  SharedStore,
  sharedStoreSymbol,
  syncClientSymbol
} from "./store";
import router from "./router";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { SyncClient } from "./lib/lib";

const syncClient = new SyncClient(true);

(window as any).syncClient = syncClient;

createApp(App)
  .use(router)
  .provide(syncClientSymbol, syncClient)
  .mount("#app");
