
import { syncClientSymbol } from "../store/index";
import { Options, Vue } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import User from "@/components/User.vue";
import { SyncClient } from "@/lib/lib";

@Options({
  components: {
    User,
  },
  name: "Socials",
})
export default class Spotify extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
}
