
import { Song, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "Queue Element",
})
export default class SongQueueComponent extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly song!: Song;
  onRemove() {
    this.syncClient.removeFromQueue(this.song);
    
  }
  play() {
    this.syncClient.playSong(this.song);
  }
}
