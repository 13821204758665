import { AppUser, Playlist, Song } from "@/lib/lib";
import { reactive } from "@vue/runtime-core";
import cuid from "cuid";
import SpotifyWebApi from "spotify-web-api-js";
import { Table } from "automerge";

export interface InternalStore {
  loggedIn: boolean;
  api: SpotifyWebApi.SpotifyWebApiJs;
  me: AppUser;
  peers: Array<AppUser>;
  searchResults: Song[];
  playlists: Playlist[];
}
export const internalStoreSymbol = Symbol("internalStore");
export const createInternalStore = function(): InternalStore {
  return reactive({
    loggedIn: false,
    api: new SpotifyWebApi(),
    me: {
      name: "",
      p2pToken: cuid()
    },
    peers: [],
    searchResults: [],
    playlists: [],
  });
};

export interface SharedStore {
  songQueue: Table<Song>;
  currentlyPlaying: Song;
}
export const sharedStoreSymbol = Symbol("sharedStore");
export const createSharedStore = function(): SharedStore {
  return reactive({
    songQueue: new Table(),
    currentlyPlaying: { name: "", spotifyId: "", artist: "", duration: 0 }
  });
};

export const syncClientSymbol = Symbol("syncClient");
