<template>
  <li
    class="
      list-group-item
      bg-primary
      d-flex
      justify-content-between
      align-items-start
    "
  >
    <div class="ms-2 me-auto">
      <div class="fw-bold">{{ song.name }}</div>
      {{ song.artist }}
    </div>
    <span><button class="btn btn-success" @click="onAdd()"><i class="bi bi-plus-circle"></i></button></span>
  </li>
</template>

<script lang="ts">
import { Song, SyncClient } from "@/lib/lib";
import { syncClientSymbol } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";

@Options({
  name: "Song",
})
export default class SongComponentSearch extends Vue {
  @Inject(syncClientSymbol) private syncClient!: SyncClient;
  @Prop() readonly song!: Song;
  onAdd() {
    console.log(`Added ${this.song.name} to search results`);
    this.syncClient.addToQueue(this.song);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
